html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

ol {
  list-style: auto;
  margin: 0;
  padding: 0 20px;
}
